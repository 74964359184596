var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-card',{staticClass:"pb-1",attrs:{"elevation":"6","x_shaped":""}},[(false && _vm.properties.media && _vm.properties.media.length)?_c('v-carousel',{attrs:{"eager":"","hide-delimiters":"","show-arrows":_vm.properties.media.length > 1,"height":'150px'}},_vm._l((_vm.properties.media),function(item,i){return _c('v-carousel-item',{key:i,class:{
        focusTop: item.mv_vertical_focus == 'top',
        focusBottom: item.mv_vertical_focus == 'bottom',
        focusTopCenter: item.mv_vertical_focus == 'top_center',
        focusBottomCenter: item.mv_vertical_focus == 'bottom_center',
        focusLeft: item.mv_horizontal_focus == 'left',
        focusRight: item.mv_horizontal_focus == 'right'
      },attrs:{"src":item.src_thumb}})}),1):_vm._e(),_c('minGallery',{attrs:{"appSettings":_vm.appSettings,"properties":_vm.properties,"isPopup":true}}),_c('div',{staticClass:"titleBox  secondary--text"},[(_vm.properties.Point_Name)?_c('v-card-title',{key:""},[_c('engAndTrans',{attrs:{"value":_vm.properties.Point_Name}})],1):_vm._e()],1),_c('PointDescriptionBody',{attrs:{"functions":{ updateShowMore: _vm.updateShowMore, openSidebarRemote: _vm.openSidebarRemote },"type":"popup","Point_Description":_vm.Point_Description,"showMore":_vm.showMore}}),_c('extraInfoBlocks',{attrs:{"appSettings":_vm.appSettings,"properties":_vm.properties,"location":_vm.properties,"media":_vm.properties.media,"Point_Name":_vm.properties.Point_Name,"Site_Marker_Number":_vm.properties.Site_Marker_Number,"structuredData":_vm.properties.structuredData,"functions":{ openYoutube: _vm.openYoutube, setSound: _vm.setSound, getAudioDetails: _vm.getAudioDetails, pointSet: _vm.pointSet },"isPopup":true}}),(_vm.$vuetify.breakpoint.mdAndDown || !_vm.appSettings.autoOpenMoreInfo)?_c('v-card-actions',{staticClass:"pb-1 mb-0 pt-0 mt-1"},[_c('v-btn',{staticClass:"pb-0 mb-0 pt-0 mt-0",attrs:{"color":'#a2c2cd',"width":_vm.appSettings.popupButtonWidth},on:{"click":function($event){return _vm.openSidebar(_vm.properties.index)}}},[_vm._v(" More Info ")]),_c('v-spacer'),(false)?_c('v-btn',{attrs:{"right":"","color":"dialogActionButtonColor","text":""},on:{"click":_vm.clearAllPopups}},[_vm._v(" Close ")]):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }