<template>
  <div class="text-center">
    <v-dialog v-model="openIntro" fullscreen>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          depressed
          v-if="true"
          :color="appSettings.mediaButtonColor"
          class="ma-2 white--text"
        >
          <v-icon>mdi-table </v-icon>
        </v-btn>
      </template>

      <v-card style="overflow:hidden">
        <v-toolbar color="indigo" dark>
          <v-toolbar-title>Table Data View</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="openIntro = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="fullHeight fullHeightMax">
          <allMapItemsTable
            :itemsPerPage="itemsPerPage"
            :itemPerPageOption="itemPerPageOption"
          ></allMapItemsTable>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import allMapItemsTable from "./revewTable/allMapItemsTable";

export default {
  name: "TableDataView",
  components: { allMapItemsTable },
  data() {
    return {
      itemsPerPage: -1,
      itemPerPageOption: [5, 10, 25, -1],

      openIntro: false,
      markerSearch: "",
      iconSize: 40
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerSections: state => state.markers.sections,
      geoJson_artwork: state => state.markers.geoJson_artwork,
      geoJson_signage: state => state.markers.geoJson_signage,
      categoryLookup: state => state.markers.categoryLookup,
      markerImages: state => state.markers.markerImages,
      markerList: state => state.markers.markerList,
      layers: state => state.filters.layers
    }),
    markerListData() {
      return this.markerList.data.features.map(row => {
        return {
          ...row.properties,
          attachedMediaCount: row?.properties?.media?.length
            ? row?.properties?.media?.length
            : 0,

          structuredDataCount: row?.properties?.structuredData
            ? _.size(row?.properties?.structuredData)
            : 0,

          coordinates: row?.geometry?.coordinates,

          hasCoordinates:
            !isNaN(row?.properties?.Longitude) &&
            !isNaN(row?.properties?.Latitude)
              ? "yes"
              : "no"
        };
      });
    }
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style scoped>
.fullHeight {
  overflow: auto;
  width: 100%;
  height: calc(100vh - 70px);
}

.fullHeightMax {
  max-height: calc(100vh - 70px);
}
</style>
