<template>
  <div>
    <center>
      <v-card-title> See the map’s features: </v-card-title>
      <div :class="['introVideoWrapper', play ? 'play' : 'stop']">
        <div class="video_thumbnail introVideoWrapperAct">
          <img
            @click="play = !play"
            v-if="!play"
            class="introVideoAct imageBorder"
            :src="`/assets/intro/${siteId}_staticVideo.jpg`"
          />
          <img
            @click="play = !play"
            v-if="play"
            class="introVideoAct imageBorder"
            :src="
              `https://storage.maptivateapp.com.au/cms/cms02/${siteObject.subsiteNumber}/assets/introVideo.gif`
            "
          />

          <div class="play-btn">
            <v-btn
              title="Play"
              color="primary"
              depressed
              fab
              xicon
              xoutlined
              xplain
              rounded
              large
              @click="play = !play"
            >
              <v-icon x-large v-if="!play">mdi-play</v-icon>
              <v-icon x-large v-if="play">mdi-stop</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </center>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "introVideo",
  props: {},
  components: {},
  data: () => ({
    play: false
  }),
  watch: {},

  computed: {
    ...mapState({
      siteObject: state => state.global.siteObject,
      siteId: state => state.global.siteId
    })
  },
  methods: {
    //do nothing
  }
};
</script>
<style>
.imageBorder {
  border: 1px solid #80808030;
  padding: 0 12px;
  border-radius: 7px;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.video_thumbnail {
  xborder: 1px solid grey;
  xborder-radius: 5px;
  position: relative;
}

.video_thumbnail:hover {
  cursor: pointer;
}

.video_thumbnail .play-btn {
  display: inline-block;
  padding: 25px;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video_thumbnail .play-btn {
  transition: top 0.5s;
}

.play .video_thumbnail .play-btn {
  top: 94%;
}

.stop .video_thumbnail .play-btn {
  top: 70%;
}

.introVideoAct {
  max-height: calc(100vh - 240px);
  max-width: 90%;
}

.introVideoWrapperAct {
  max-height: calc(100vh - 230px);
  max-width: 90%;
}

@media only screen and (max-height: 780px) and (min-width: 1264px) {
  .introVideoAct {
    max-height: calc(100vh - 300px);
    max-width: 80%;
  }

  .introVideoWrapperAct {
    max-height: calc(100vh - 290px);
    max-width: 80%;
    max-width: 95%;
  }
}

@media only screen and (max-width: 1264px) {
  .introVideoAct {
    max-height: calc(100vh - 300px);
    max-width: 80%;
  }

  .introVideoWrapperAct {
    max-height: calc(100vh - 290px);
    max-width: 80%;
    max-width: 95%;
  }

  .play .video_thumbnail .play-btn {
    top: 100%;
  }
}
</style>
