var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.xsOnly
        ? '95%'
        : _vm.$vuetify.breakpoint.mdAndDown
        ? 500
        : 800},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:" ",on:{"click":function($event){return _vm.scrollToSiteDelay()}}},'span',attrs,false),on),[_vm._v(" Welcome ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"dialog px-3"},[_c('div',{staticClass:"d-flex flex-row  justify-space-between ",staticStyle:{"width":"100%"}},[_c('div'),(true)?_c('v-card-title',{staticClass:"  xlighten-2 secondary--text text-center justify-center siteHeadingText",style:({
            background: 'white'
          })},[_vm._v(" Welcome to "+_vm._s(_vm.siteObject.title)+" ")]):_vm._e(),_c('v-btn',{staticStyle:{"margin-right":"-10px","margin-top":"4px"},attrs:{"icon":"","color":"black"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mdAndDown),expression:"!$vuetify.breakpoint.mdAndDown"}],attrs:{"cols":"12","lg":"6"}},[_c('intoVideo')],1),_c('v-col',{staticClass:"contentWrapper",attrs:{"cols":"12","lg":"6","id":"AboutContentWrapper"},on:{"scroll":_vm.onScroll}},[(_vm.siteId == 'mz' || _vm.siteId == 'worz' || _vm.siteId == 'hs')?_c('WelcomeDialog_gen',{attrs:{"dialog":_vm.dialog,"isReady":_vm.isReady}}):_vm._e(),(_vm.siteId == 'kfp')?_c('WelcomeDialog_kfp',{attrs:{"dialog":_vm.dialog,"isReady":_vm.isReady}}):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"px-0 mt-3"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.nav_faq()}}},[_vm._v(" FAQ ")]),_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndDown && !_vm.atTop),expression:"$vuetify.breakpoint.mdAndDown && !atTop"}],staticClass:"v-fade",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.scrollToSite(false)}}},[_vm._v(" Top ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }