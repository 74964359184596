<template>
  <div class="enableLocationFAQ">
    <center>
      <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
        <strong>Android devices</strong>
      </p>
    </center>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>Step 1: Open Settings</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      1. Go to the <strong>Settings</strong> app on your Android device. You can
      find it in your app drawer or by swiping down from the top of the screen
      and tapping the gear icon.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>Step 2: Access Location Settings</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      2. Scroll down and tap on <strong>Location</strong>. This might also be
      under the <strong>Privacy</strong> or
      <strong>Security &amp; location</strong> section depending on your Android
      version.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>Step 3: Turn On Location Services</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      3. Make sure the <strong>Location</strong> toggle is switched on. If
      it&apos;s turned off, tap it to enable location services.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;<strong>Step 4: Manage App Permissions</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      4. Under the <strong>Location</strong> menu, tap on
      <strong>App permissions</strong> (or
      <strong>Location permissions</strong>).
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;<strong>Step 5: Find Your Browser</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      5. In the list of apps, locate your browser (e.g.,
      <strong>Chrome</strong>, <strong>Firefox</strong>, <strong>Edge</strong>,
      or <strong>Samsung Internet</strong>), and tap on it.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;<strong>Step 6: Adjust Location Permissions</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      6. You&rsquo;ll be given a few options for location access:
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>Allow all the time</strong>: The browser can use
      your location in the background.
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>Allow only while using the app</strong>: Location
      is only accessed when you&apos;re using the browser.
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>Ask every time</strong>: The browser will ask
      permission each time it needs location access.
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>Deny</strong>: The browser won&rsquo;t be able to
      access your location.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 7: Confirm and Exit</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      7. After selecting your preferred option, exit the settings.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 8: Test the Settings</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      8. Open your browser and navigate to a website that uses location services
      (e.g., Google Maps) to test if it works.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>

    <center>
      <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
        <strong>iPhone Devices</strong>
      </p>
    </center>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      To enable location settings for your browser on an iPhone, follow these
      steps:
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 1: Open Settings</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      1. Unlock your iPhone and tap on the <strong>Settings</strong> app (gear
      icon).
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 2: Access Privacy Settings</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      2. Scroll down and tap on <strong>Privacy &amp; Security</strong>.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 3: Go to Location Services</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      3. Tap on <strong>Location Services</strong> at the top of the Privacy
      menu.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 4: Turn on Location Services</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      4. Make sure that the <strong>Location Services</strong> toggle at the top
      is turned on. If it&apos;s off, tap it to turn it on.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;</strong>
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 5: Manage Browser Permissions</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      5. Scroll down to the list of apps and find your browser (e.g.,
      <strong>Safari</strong>, <strong>Chrome</strong>,
      <strong>Firefox</strong>).
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 6: Adjust Location Permissions for the Browser</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      6. Tap on your browser app and select one of the following options:
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>Never</strong>: The app won&rsquo;t access your
      location.
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>Ask Next Time or When I Share</strong>: The app
      will ask for permission each time it needs your location.
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>While Using the App</strong>: The app can only
      access your location when it is actively in use.
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      &nbsp; &nbsp; - <strong>Always</strong>: The app can access your location
      even when you&apos;re not using it.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 7: Enable Precise Location (Optional)</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      7. If you want your browser to access your exact location, make sure the
      <strong>Precise Location</strong> toggle is turned on. If it&apos;s off,
      your browser will only receive your approximate location.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      <strong>&nbsp;Step 8: Test the Browser</strong>
    </p>
    <p
      style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;margin-left:36.0pt;'
    >
      8. Open your browser and navigate to a website that requires location
      access (e.g., Maps or a weather site) to confirm that location services
      are working correctly.
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
    <p style='margin:0cm;font-size:16px;font-family:"Aptos",sans-serif;'>
      &nbsp;
    </p>
  </div>
</template>

<script>
export default {
  name: "FAQ_Modal_GPS_SecSecttings",

  components: {},
  data() {
    return {};
  },
  methods: {},

  computed: {},
  created() {}
};
</script>

<style scoped>
.enableLocationFAQ {
  text-align: left;
}

.primaryList {
  list-style-type: disc;
}

.secondaryList {
  list-style-type: circle;
}
</style>
