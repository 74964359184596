const sites = [
  {
    index: 0,
    key: "mz",
    title: "Melbourne Zoo",
    titleShort: "Melbourne Zoo",
    short: "Melbourne",

    titleShortLine1: "Melbourne",
    titleShortLine2: "Zoo",
    maptivateUrl: "https://melbournezoo.maptivateapp.com.au",
    url: "https://www.zoo.org.au/melbourne",
    mapurl: "https://www.zoo.org.au/melbourne/melbourne-zoo-maps/",
    cmsUrl: "https://cms02.maptivateapp.com.au/melbourne-zoo",
    editPost:
      "https://cms02.maptivateapp.com.au/melbourne-zoo/wp-admin/post.php?post=",
    siteSetup: require("../service/sites/mz/siteSetupMz"),

    subsiteNumber: "2",
    subsiteId: "melbourne-zoo",
    shareTitle: "Melbourne Zoo Maptivate",
    shareDescription:
      "Explore Melbourne Zoo via an interactive digital map! Navigate around the zoo, check out the keeper talks, watch videos and learn more about the precious wildlife that call our zoo home."
  },

  {
    index: 1,
    key: "hs",
    title: "Healesville Sanctuary",
    titleShort: "Healesville Sanctuary",
    short: "Healesville",
    titleShortLine1: "Healesville",
    titleShortLine2: "Sanctuary",
    maptivateUrl: "https://healesvillesanctuary.maptivateapp.com.au/",

    url: "https://www.zoo.org.au/healesville",
    mapurl: "https://www.zoo.org.au/healesville/healesville-sanctuary-maps/",
    cmsUrl: "https://cms02.maptivateapp.com.au/healesville-sanctuary",
    editPost:
      "https://cms02.maptivateapp.com.au/healesville-sanctuary/wp-admin/post.php?post=",
    siteSetup: require("../service/sites/hs/siteSetupHs"),

    subsiteNumber: "3",
    subsiteId: "healesville-sanctuary",
    shareTitle: "Healesville Sanctuary Maptivate",
    shareDescription:
      "Explore Healesville Sanctuary via an interactive digital map! Navigate around the zoo, check out the keeper talks, watch videos and learn more about the precious wildlife that call our sanctuary home."
  },

  {
    index: 2,
    key: "worz",
    title: "Werribee Open Range Zoo",
    titleShort: "Werribee Zoo",
    short: "Werribee",
    titleShortLine1: "Werribee",
    titleShortLine2: "Zoo",
    maptivateUrl: "https://werribeeopenrangezoo.maptivateapp.com.au/",
    url: "https://www.zoo.org.au/werribee",
    mapurl: "https://www.zoo.org.au/werribee/werribee-zoo-maps/",
    cmsUrl: "https://cms02.maptivateapp.com.au/werribee-open-range-zoo",
    editPost:
      "https://cms02.maptivateapp.com.au/werribee-open-range-zoo/wp-admin/post.php?post=",
    siteSetup: require("../service/sites/worz/siteSetupWorz"),

    subsiteNumber: "4",
    subsiteId: "werribee-open-range-zoo",
    shareTitle: "Werribee Open Range Zoo Maptivate",
    shareDescription:
      "Explore Werribee Open Range Zoo via an interactive digital map! Navigate around the zoo, check out the keeper talks, watch videos and learn more about the precious wildlife that call our zoo home."
  },

  {
    index: 3,
    key: "kfp",
    title: "Kyabram Fauna Park",
    tittitleShortle: "Kyabram Fauna Park",
    short: "Kyabram",
    titleShortLine1: "Kyabram",
    titleShortLine2: "Fauna Park",
    maptivateUrl: "https://kyabramfaunapark.maptivateapp.com.au/",

    url: "https://www.zoo.org.au/kyabram",
    mapurl: "https://www.zoo.org.au/kyabram/kyabram-fauna-park-maps/",
    cmsUrl: "https://cms02.maptivateapp.com.au/kyabram-fauna-park",
    editPost:
      "https://cms02.maptivateapp.com.au/kyabram-fauna-park/wp-admin/post.php?post=",
    siteSetup: require("../service/sites/kfp/siteSetupKfp"),

    subsiteNumber: "5",
    subsiteId: "kyabram-fauna-park",
    shareTitle: "Kyabram Fauna Park Maptivate",
    shareDescription:
      "Explore Kyabram Fauna Park via an interactive digital map! Navigate around the zoo, check out the keeper talks, watch videos and learn more about the precious wildlife that call our park home."
  }
];

const defaultSiteKey = sites[0].key;
const siteLookup = sites.reduce(function(map, obj) {
  map[obj.key] = obj;
  return map;
}, {});

const getId = () => {
  try {
    let id = window.siteMode;

    if (
      id &&
      typeof id === "string" &&
      id.length &&
      id.length < 10 &&
      siteLookup &&
      siteLookup[id]
    ) {
      return id;
    } else {
      throw { error: true };
    }
  } catch (error) {
    return defaultSiteKey;
  }
};

const currentSiteId = getId();
const currentSiteObj = siteLookup[currentSiteId];

module.exports.getId = () => {
  return currentSiteId;
};
module.exports.getSites = () => {
  return JSON.parse(JSON.stringify(sites));
};
module.exports.getSiteObj = () => {
  let setup = { ...currentSiteObj };

  delete setup.siteSetup;

  return setup;
};

module.exports.getSiteSetup = () => {
  let setup = { ...currentSiteObj.siteSetup };

  try {
    setup.global.appSettings.wpEditSite = currentSiteObj.editPost;
  } catch (error) {
    //do nothing;
  }

  return setup;
};
