<template>
  <center id="topOfLayerInfoSection">
    <v-card-title id="aboutHeading_keepertalks" class="justify-center"
      >Keeper Talks
    </v-card-title>
    <v-card-text>
      Learn about the animals who call Werribee Open Range Zoo home, including
      stories about their individual personalities and behaviours.
    </v-card-text>
    <br />
    <br />
    <br />
    <br />
    <v-card-title id="aboutHeading_sensory" class="justify-center"
      >Sensory Awareness
    </v-card-title>
    <v-card-text>
      We are committed to creating an accessible environment for all our
      visitors. This layer allows you to plan your visit around high- and
      low-sensory areas of the zoo. Descriptions of the icons are displayed in
      the Map Legend in the bottom right corner of the map.
    </v-card-text>
    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title
      id="aboutHeading_fighting_extinction_species"
      class="justify-center"
      >Fighting Extinction
    </v-card-title>
    <v-card-text
      >Fighting wildlife extinction; it’s not just what we do, it’s who we are.
      We are proud to and deliver more breeding and recovery programs than any
      other institution within Australia. In particular, there are 27 local
      threatened species we are committed to saving, some of which live at
      Werribee Open Range Zoo.
    </v-card-text>
    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="aboutHeading_retail_and_eating" class="justify-center"
      >Food and Shopping
    </v-card-title>
    <v-card-text
      >Treat yourself to food and coffee from one of our cafés, enjoy a bit of
      shopping in the retail shop, or check out the various facilities that you
      can enjoy at the zoo.
    </v-card-text>
    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="aboutHeading_viewing_area" class="justify-center"
      >Viewing Area
    </v-card-title>
    <v-card-text
      >Check out the viewing areas for the best spot to see our animals!
    </v-card-text>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </center>
</template>

<script>
export default {
  name: "LayerInfo_WORZ"
};
</script>
