<template>
  <v-carousel
    class="mv_miniGallery disable_enableAltAspectRation"
    flat
    v-if="media && media.length"
    hide-delimiters
    :show-arrows="media.length > 1"
    :height="isPopup ? '150px' : '190px'"
  >
    <v-carousel-item
      v-for="(item, i) in media"
      :key="i"
      :src="item.src_thumb"
      :class="{
        focusTop: item.mv_vertical_focus == 'top',
        focusBottom: item.mv_vertical_focus == 'bottom',
        focusTopCenter: item.mv_vertical_focus == 'top_center',
        focusBottomCenter: item.mv_vertical_focus == 'bottom_center',
        focusLeft: item.mv_horizontal_focus == 'left',
        focusRight: item.mv_horizontal_focus == 'right',
        tallAspectImage: item.aspectRatiosClass == 'tallAspectImage',
        mediumTallAspectImage: item.aspectRatiosClass == 'mediumTallAspectImage'
      }"
      eager
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
//const srcThumbHeight = 420;
//const srcThumbWidth = 450;

export default {
  name: "minGallery",
  components: {},
  data: () => ({}),
  props: {
    appSettings: Object,

    properties: Object,
    isPopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    media() {
      let media = [];

      if (this?.properties?.media?.length) {
        media = JSON.parse(JSON.stringify(this.properties.media));
        /*
        media.forEach(item => {

          let {
            fullAspectRatios,
            fullWidth,
            fullHeight,
            thumbAspectRatios,
            thumbWidth,
            thumbHeight
          } = item;

          if (
            (fullAspectRatios,
            fullWidth,
            fullHeight,
            thumbAspectRatios,
            thumbWidth,
            thumbHeight)
          ) {
            //do nothing;
          }

          //check for tall
          if (thumbWidth == srcThumbWidth && thumbHeight == srcThumbHeight) {
            //thumb is maxed out,

            //look at ratio
            if (fullAspectRatios < 0.85) {
              item.aspectRatiosClass = "tallAspectImage";
            } else if (fullAspectRatios < 1.1) {
              item.aspectRatiosClass = "mediumTallAspectImage";
            }
          }
        });*/
      }

      return media;
    }
  },
  methods: {},
  mounted() {}
};
</script>
<style>
.focusTop .v-image .v-image__image {
  background-position-y: 0% !important;
}

.focusBottom .v-image .v-image__image {
  background-position-y: 100% !important;
}
.focusTopCenter .v-image .v-image__image {
  background-position-y: 25% !important;
}
.focusBottomCenter .v-image .v-image__image {
  background-position-y: 75% !important;
}
.focusLeft .v-image .v-image__image {
}
.focusRight .v-image .v-image__image {
}

.enableAltAspectRation .tallAspectImage .v-image .v-image__image {
  background-size: 60%;
}
.enableAltAspectRation .mediumTallAspectImage .v-image .v-image__image {
  background-size: 80%;
}

.mv_miniGallery {
  background: black;
}
.mv_miniGallery .v-image__image.v-image__image--preload {
  background: #7b7b7b;
  color: #7b7b7b;
}
.mv_miniGallery .v-image__image--preload {
  filter: none;
}

/*
.mv_miniGallery .v-image {

  backgroundxx: transparent !important;  
}

.mv_miniGallery .v-image__image{
  background: black;
}

.mv_miniGallery .v-image__image.v-image__image--preload,
.mv_miniGallery  .v-window-item
{
  background: #ebe6d4 ;
  background: red ;
}*/
</style>
