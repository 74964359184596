//import _, { initial } from "lodash";

import { mapState } from "vuex";
import { EmptyTargetControl } from "./../controls/emptyTargetControl.js";

import shareButtonUi from "./../controls/shareButton.vue";

export default {
  data: () => ({
    //layerState: false
    currentPopup: null
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  watch: {},
  methods: {
    addShareButtons() {
      let divTargetId = "mapShareListFakeDivTarget";

      var emprtyTargetShareListOptions = {
        persistList: true,
        extraClass: "fakeEmptyMenuClass mapShareListDiv",
        id: divTargetId
      };
      const emptyTargetShareListLegend = new EmptyTargetControl(
        emprtyTargetShareListOptions
      );
      //this.map.addControl(emptyTargetControl, 'top-right');
      this.map.addControl(emptyTargetShareListLegend, "top-left");

      try {
        new shareButtonUi({
          propsData: {
            vuetifyCopy: this.$vuetify,
            appSettings: this.appSettings
          }
        }).$mount(`#${divTargetId}`);
      } catch (error) {
        //do nothink;
      }
    }
  },
  created() {},
  destroyed() {},
  mounted() {}
};
