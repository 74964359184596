var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.media && _vm.media.length)?_c('v-carousel',{staticClass:"mv_miniGallery disable_enableAltAspectRation",attrs:{"flat":"","hide-delimiters":"","show-arrows":_vm.media.length > 1,"height":_vm.isPopup ? '150px' : '190px'}},_vm._l((_vm.media),function(item,i){return _c('v-carousel-item',{key:i,class:{
      focusTop: item.mv_vertical_focus == 'top',
      focusBottom: item.mv_vertical_focus == 'bottom',
      focusTopCenter: item.mv_vertical_focus == 'top_center',
      focusBottomCenter: item.mv_vertical_focus == 'bottom_center',
      focusLeft: item.mv_horizontal_focus == 'left',
      focusRight: item.mv_horizontal_focus == 'right',
      tallAspectImage: item.aspectRatiosClass == 'tallAspectImage',
      mediumTallAspectImage: item.aspectRatiosClass == 'mediumTallAspectImage'
    },attrs:{"src":item.src_thumb,"eager":""}})}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }